import React from 'react'
import DesktopImg from '../../assets/img/stores/desktop.png';
import PlayStoreImg from '../../assets/img/stores/play_store.png';
import AppStoreImg from '../../assets/img/stores/app_store1.png';
import HuaweiStoreImg from '../../assets/img/stores/huawei_store.png';
import desktopDownload from '../../assets/downloads/alkuppiyadesktop-1124.exe';

function KuppiyaDownloadNow() {
    // const DownloadButton = props => {
    //     const downloadFile = () => {
    //       window.location.href = "https://yoursite.com/src/assets/files/exampleDoc.pdf"
    //     }
    //     return (
    //               <button onClick={downloadFile} />
    //     )
    //   }

    return(
        <>
            <div className="col-lg px-4 ">
                <h3 className="mb-3"><strong>දැන්ම Download කරගන්න</strong></h3>
                <div className="row mt">
                    <div className="col-6 ">
                        <p> Computers</p>
                        <a href={'https://storage.cloud.google.com/al-kuppiya-store/desktopapp/alkuppiyadesktop-1124.exe'} download>
                        <img style={{display:"block"}} src={DesktopImg} alt="desktop_icon " className="store_badge " />
                        </a>
                    </div>
                    <div className="col-6 ">
                        <p>Android Devices</p>
                        <a href={"https://play.google.com/store/apps/details?id=com.alkuppiya.mobile&hl=en&gl=US"} download>
                        <img style={{display:"block"}} src={PlayStoreImg} alt="playstore_icon " className="store_badge " />
                        </a>
                    </div>
                </div>
                <br />
                <div className="row ">
                    <div className="col-6 ">
                        <p>Apple Devices</p>
                        <img style={{display:"block"}} src={AppStoreImg} alt="app_store_icon " className="store_badge " />
                    </div>
                    <div className="col-6 ">
                        <p>Huawei Devices</p>
                        <a href={"https://appgallery.huawei.com/#/app/C104516559"}>
                        <img style={{display:"block"}} src={HuaweiStoreImg} alt="hstore_icon " className="store_badge " />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KuppiyaDownloadNow;