import React from 'react';
import CrayonsImg from '../../assets/img/logos/Crayons.png';
// import KuppiyaVODImg from '../../assets/img/logos/Kuppiya_VOD_3.png';
// import KuppiyaLiveImg from '../../assets/img/logos/Kuppiya_Live_3.png';
// import KuppiyaStoreImg from '../../assets/img/logos/Kuppiya_Store_3_3.png';
// import TutionHandiyaImg from '../../assets/img/logos/Tution_handiya_logo.png';
import allImg from '../../assets/img/all.png';
import awardsImg from '../../assets/img/awards.png';
import {
    Link
  } from "react-router-dom";

function KuppiyaFooter() {
    return(
        <>
            <div className="col-lg-2 float-left bottom-col ">
                <div className="ml-5 ">
                    <img src={CrayonsImg} className="clogo " alt=" " />
                </div>
            </div>

            <div className="col-lg-3 bottom-col " style={{textAlign:"left",margin:"auto"}}>
                <Link to="/tandc" style={{color:"white"}}>Terms and Conditions</Link>
                {/* <br /> */}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/privacypolicy" style={{color:"white"}}>&copy;&nbsp;Privacy Policy</Link>
            </div>
            <div className="col-lg-3 bottom-col " style={{padding:"0px"}}>
                <div className="blogos row ">
                    <a href="# " style={{marginLeft:"auto"}}>
                        <img src={allImg} alt="KuppiyaVODImg " />
                    </a>
                </div>

            </div>
            <div className="col-lg-3 bottom-col " style={{padding:"10px 0px 0px 0px"}}>
                <div className="blogos row ">
                    <a href="# ">
                        <img src={awardsImg} alt="KuppiyaLiveImg " />
                    </a>
                </div>
            </div>
        </>
    )
}

export default KuppiyaFooter;