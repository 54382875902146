import React from 'react';
import MainBanner from '../../assets/img/main_banner.png';

function KuppiyaMainBanner() {
    return(
        <>
            <img src={MainBanner} alt="main_banner_al_kuppiya " style={{width:"90%",marginTop:"55px"}} />
        </>
    )
}

export default KuppiyaMainBanner;