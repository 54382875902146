import React from 'react';
import KuppiyaNavBar from '../KuppiyaNavBar';
import TnCBody from './TnCBody';
import KuppiyaFooter from '../HomePage/KuppiyaFooter';

function TnCPage() {
    return(
        <>
            <div className="bg-black">
                <div className="main">
                    <KuppiyaNavBar />
                    <TnCBody />
                    <div className="row f-width pad-lr " style={{margin:"0px"}}>
                    <KuppiyaFooter />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TnCPage;